var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fetchingFileVersions
    ? _c("v-skeleton-loader", { attrs: { type: "article, table" } })
    : _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c("span", { staticClass: "secondary--text font-weight-bold" }, [
              _vm._v("File Compare"),
            ]),
          ]),
          _c("v-card-subtitle", [_vm._v(_vm._s(_vm.fileName))]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", color: "grey lighten-3" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "text-subtitle-1" },
                            [
                              _vm._v(" This version "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "ml-1",
                                                attrs: { color: "info" },
                                              },
                                              on
                                            ),
                                            [_vm._v("mdi-information-outline")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "This is the current version of your file."
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-card-text", { staticClass: "text-body-1" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  this.$store.getters[
                                    "snapshotStore/snapshotLongNameById"
                                  ](this.$route.params.snid)
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", color: "grey lighten-3" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "text-subtitle-1" },
                            [_vm._v("That version")]
                          ),
                          _c("v-card-text", [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.snapshots,
                                    dense: "",
                                    solo: "",
                                    "hide-details": "",
                                    "return-object": "",
                                    "item-text": "snapshot_long_id",
                                    label: "select snapshot",
                                    loading: _vm.compareLoading,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.compareFiles()
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center w-100",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mr-5" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.snapshot_long_id
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "div",
                                                [
                                                  !_vm.isColdStorage(item.snid)
                                                    ? _c(
                                                        "span",
                                                        { staticClass: "mr-5" },
                                                        [
                                                          _vm._v(
                                                            " (" +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "dateTimeToHuman"
                                                                )(
                                                                  item.last_modified_timestamp
                                                                )
                                                              ) +
                                                              ") "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.isColdStorage(item.snid)
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            outlined: "",
                                                            color: "primary",
                                                          },
                                                        },
                                                        [_vm._v("cold storage")]
                                                      )
                                                    : _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            outlined: "",
                                                          },
                                                        },
                                                        [_vm._v("filesystem")]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.selectedSnapshot,
                                    callback: function ($$v) {
                                      _vm.selectedSnapshot = $$v
                                    },
                                    expression: "selectedSnapshot",
                                  },
                                }),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { "max-width": "500" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function ({ on: dialog }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on: tooltip,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                  icon: "",
                                                                  disabled:
                                                                    _vm.disableRestore ||
                                                                    _vm.selectedSnapshot ===
                                                                      null,
                                                                  loading:
                                                                    _vm.restoreLoading,
                                                                },
                                                              },
                                                              {
                                                                ...tooltip,
                                                                ...dialog,
                                                              }
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-restore-alert"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Restore")])]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.restoreDialog,
                                      callback: function ($$v) {
                                        _vm.restoreDialog = $$v
                                      },
                                      expression: "restoreDialog",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c("v-card-title", [
                                          _c(
                                            "div",
                                            { staticClass: "secondary--text" },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [_vm._v("mdi-restore-alert")]
                                              ),
                                              _vm._v(" File Restore "),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("v-divider", {
                                          staticClass: "mb-1",
                                        }),
                                        _c("v-card-text", [
                                          _vm._v(
                                            " Restoring a previous version of a file will replace the current version of the file in your current state with the previous. "
                                          ),
                                        ]),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "error",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.restoreFile()
                                                  },
                                                },
                                              },
                                              [_vm._v("restore this version")]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "secondary",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.restoreDialog = false
                                                  },
                                                },
                                              },
                                              [_vm._v("cancel")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.diffs
                ? _c("div", { domProps: { innerHTML: _vm._s(_vm.prettyHtml) } })
                : _vm.selectedSnapshot !== null &&
                  !_vm.compareError &&
                  !_vm.compareLoading
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-5",
                      attrs: {
                        prominent: "",
                        type: "info",
                        text: "",
                        dense: "",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(
                              "The file version you have selected is identical to what you currently have."
                            ),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "shrink" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    to: {
                                      name: "snapshot-files",
                                      params: {
                                        oid: _vm.$route.params.oid,
                                        sid: _vm.$route.params.sid,
                                        iid: _vm.$route.params.iid,
                                        snid: _vm.$route.params.snid,
                                        localPath: _vm.currentFileLocalPath,
                                        fileArea: _vm.fileAreaType,
                                      },
                                    },
                                    text: "",
                                    exact: "",
                                    color: "secondary",
                                  },
                                },
                                [_vm._v(" go back to files ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.selectedSnapshot !== null &&
                  _vm.compareError &&
                  !_vm.compareLoading
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-5",
                      attrs: {
                        prominent: "",
                        type: "warning",
                        text: "",
                        dense: "",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(_vm._s(_vm.compareErrorMessage)),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "shrink" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    to: {
                                      name: "snapshot-files",
                                      params: {
                                        oid: _vm.$route.params.oid,
                                        sid: _vm.$route.params.sid,
                                        iid: _vm.$route.params.iid,
                                        snid: _vm.$route.params.snid,
                                        localPath: _vm.currentFileLocalPath,
                                        fileArea: _vm.fileAreaType,
                                      },
                                    },
                                    text: "",
                                    exact: "",
                                    color: "secondary",
                                  },
                                },
                                [_vm._v(" go back to files ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }